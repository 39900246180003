<template>
  <div>
    Redirecionando...
  </div>
</template>
<script>
import { User } from "@/api/account";
export default {
  name: "Logout",
  mounted() {
    User.logout().finally(() => {
      this.$state.role = "";
      this.$state.user = {};
      this.$state.token = null;
      this.$router.push({ name: "app:login" });
    });
  },
};
</script>
