import { http } from "@/api";

export const User = {
  login: (username, password) => {
    return http.post("/users/api-auth", {
      username,
      password,
    });
  },
  logout: () => {
    return http.get("/users/api-logout");
  },

  me: () => {
    return http.get("/users/me/");
  },
};
